<template>
  <v-card class="pa-4">
    <v-card-title
      >Enviar liquidación "{{ value.canal }} -
      {{ parseDate(value.fechaLiquidacion) }}"</v-card-title
    >
    <v-divider />

    <v-card-text style="max-height: 600px;">
      <v-subheader>Enviar a:</v-subheader>
      <v-list>
        <v-list-item v-for="(_, i) in correos" :key="i">
          <v-text-field
            filled
            dense
            hide-details
            :rules="[rules.req]"
            v-model="correos[i]"
          ></v-text-field>
          <v-list-item-action>
            <v-btn @click="quitarCorreo(i)" icon color="error">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <div class="flex-grow-1"></div>
          <v-list-item-action>
            <v-btn small fab color="primary" @click="correos.push('')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-subheader>Añadir mensaje:</v-subheader>
      <v-textarea filled auto-grow no-resize hide-details v-model="msg"></v-textarea>

    </v-card-text>

    <v-divider />

    <v-card-actions class="d-flex justify-end">
      <v-btn
        class="mr-3 mt-2"
        small
        color="primary"
        @click="sendToUser"
        :loading="sendingToUser"
      >
        <v-icon left>mdi-email-fast</v-icon>
        Enviar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";
import { parseDate } from "@/utils/index.js";

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      rules: { req },
      correos: [ this.value.correo ],
      msg: null,
      sendingToUser: false,
    };
  },
  methods: {
    parseDate,
    quitarCorreo(index) {
      this.correos.splice(index, 1);
    },
    async sendToUser() {
      this.sendingToUser = true;
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/liquidaciones/enviarAComercial.php`,
        // responseType: "blob",
        params: {
          idUsuario: this.value.idUsuario,
        },
        data: {
          idUsuario: this.value.idUsuario,
          correos: this.correos,
          msg: this.msg,
          token: this.$store.getters.getJwtEmpresa,
          // idFactura: soloEsta ? this.datos.idFactura : null,
        },
      }).then(() => {
        this.$root.$emit('snack', 'Enviada con éxito')
      })
      .finally(() => {
        this.sendingToUser = false;
        this.$emit('close')
      });
    }
  },
};
</script>

<style></style>
